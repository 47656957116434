import React from "react";
import Image from "next/legacy/image";
import { Link } from "../Link";
import { getResizedImageURL } from "../utils/get-resized-image-url";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { EcologiColour } from "../theme/ecologi-colors";

export type TestimonialProps = {
  image?: {
    borderColour?: EcologiColour;
    size?: number;
    url: string;
  };
  link?: {
    // FIXME: Worth making `link` a standard type for any component handling a link?
    colour?: EcologiColour;
    openInNewTab?: boolean;
    text: string;
    url: string;
  };
  testimony: string;
  testimonySource: string;
  textColour?: EcologiColour;
  title?: string;
};

const DEFAULT_IMAGE_SIZE = 160;
const MULTIPLICATION_FACTOR = 1.5;

const Blockquote = styled.blockquote`
  margin: 0 0 32px;

  @media ${({ theme }) => theme.bp.mobileUp} {
    margin: 0 0 40px;
  }
`;

const Cite = styled.cite`
  font-size: 15px;
  font-style: normal;
  opacity: 0.8;
`;

const ImageContainer = styled.div<{ borderColour: EcologiColour }>`
  border-radius: 100%;
  margin-bottom: 30px;

  > div {
    display: block !important; // Overrides Next.js' inline style
  }

  img {
    border-radius: 100%;
  }

  ${(props) => props.borderColour && `border: 6px solid ${props.borderColour}`};
`;

const StyledLink = styled(Link)<{ colour?: string | undefined }>`
  font-weight: bold;

  ${(props) =>
    props.colour &&
    css`
      color: ${props.colour};
    `}
`;

const Testimony = styled.p`
  font-weight: bold;
  line-height: 1.6;
  font-size: 18px;
  margin-bottom: 16px;
  padding: 0 10px;
`;

const Title = styled.h4`
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Wrapper = styled.div<{ textColour: EcologiColour }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ textColour }) =>
    css`
      color: ${textColour};
    `}
`;

export const Testimonial = ({
  image,
  link,
  textColour = EcologiColour.charcoal,
  title,
  testimony,
  testimonySource,
}: TestimonialProps) => {
  const imageWidth = image?.size ?? DEFAULT_IMAGE_SIZE;
  const imageHeight = imageWidth;

  const resizeImageUrl = (
    url: string,
    dimensions: [width: number, height: number]
  ) => {
    return getResizedImageURL(
      url,
      dimensions[0] * MULTIPLICATION_FACTOR,
      dimensions[1] * MULTIPLICATION_FACTOR,
      {
        crop: true,
      }
    );
  };

  const resizedImageUrl = image?.url
    ? resizeImageUrl(image.url, [imageWidth, imageHeight]) || ""
    : "";

  return (
    <Wrapper data-testid="testimonial-wrapper" textColour={textColour}>
      {image && (
        <ImageContainer borderColour={image.borderColour as EcologiColour}>
          <Image
            width={imageWidth}
            height={imageHeight}
            alt={`Testimonial image for ${testimonySource}`}
            data-testid="testimonial-image"
            src={resizedImageUrl}
          />
        </ImageContainer>
      )}
      {title && <Title>{title}</Title>}
      <Blockquote cite={testimonySource}>
        <Testimony>{`"${testimony}"`}</Testimony>
        <footer>
          <Cite>{testimonySource}</Cite>
        </footer>
      </Blockquote>
      {link && (
        <StyledLink
          className="link"
          colour={link.colour}
          data-testid="testimonial-link"
          href={link.url}
          rel="noopener noreferrer"
          target={link.openInNewTab ? "_blank" : "_self"}
        >
          {link.text}
        </StyledLink>
      )}
    </Wrapper>
  );
};
