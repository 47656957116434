import React from "react";
import { Wrapper } from "../Wrapper";
import { Text } from "../Text";
import { theme } from "../theme";
import styles from "./Testimonials.module.scss";
import { isZeroPage } from "../utils/is-zero";
import { Testimonial } from "../Testimonial/Testimonial";
import { TestimonialsData } from "@ecologi/shared";
import { EcologiColour } from "../theme/ecologi-colors";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

type TestimonialsProps = {
  data: TestimonialsData;
  slug: string;
};

const DEFAULT_TITLE = "What our customers say";

const StyledText = styled(Text)<{ isZero?: boolean }>`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 60px;

  ${(props) =>
    props.isZero &&
    css`
      padding: "10px";
    `}
`;

export const Testimonials = ({ data, slug }: TestimonialsProps) => {
  const isZero = isZeroPage(slug);

  return (
    <section
      className={styles.wrapper}
      style={{ backgroundColor: data.background_colour || theme.colors.mist }}
    >
      <Wrapper isExtraLarge>
        <StyledText isSubheading isCenter text={data.title || DEFAULT_TITLE} />
        <ul className={styles.cols}>
          {data.testimonials.map((testimonial, i) => (
            <li className={styles.col} key={i}>
              <Testimonial
                image={{
                  size: 160,
                  url: testimonial.image.url,
                }}
                link={{
                  colour: isZero ? EcologiColour.darkBlue : undefined,
                  openInNewTab: true,
                  text: "View profile",
                  url: `/${testimonial.organisation_offset_earth_username}`,
                }}
                testimony={testimonial.testimony}
                testimonySource={testimonial.person_name}
                title={testimonial.organisation_name}
              />
            </li>
          ))}
        </ul>
      </Wrapper>
    </section>
  );
};
